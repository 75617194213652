:root {
  --title-color-blue: rgb(41, 171, 226);
  --title-color-dark-blue: rgb(36, 152, 201);
  --title-color-darken-blue: rgb(29, 123, 163);
  --title-color-darkest-blue: #1B1464;
  --default-text-size: 18px;
  --sm-text-size: 22px;
  --md-text-size: 28px;
  --lg-text-size: 32px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: IBMPlexSans;
}

a {
  color: var(--title-color-blue)!important;
  text-decoration: none!important;
}

textarea, input{
  border: 1px solid #222;
}


.text-blue {
  color: var(--title-color-blue);
}
.text-dark-blue {
  color: var(--title-color-dark-blue);
}
.text-darken-blue {
  color: var(--title-color-darken-blue);
}

.default-text-size{
  font-size: var(--default-text-size);
}
.sm-text-size{
  font-size: var(--sm-text-size);
}
.md-text-size{
  font-size: var(--md-text-size);
}
.lg-text-size{
  font-size: var(--lg-text-size);
}
.error-text{
  font-size: 14px;
  padding-top: 3px;
  color: #ff0404;
}

.cursor-pointer{
  cursor: pointer;
}

@for $i from 1 through 100 {
  .indent-#{$i}{
    margin-left: $i *20px!important;
  }
}

.contact-sticky{
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 30px;
  width: 160px;
  padding: 8px 20px;
  background-color: #fff;
  z-index: 8;
  -webkit-box-shadow: 0px 1px 4px 0px #adadad;
  // -webkit-box-shadow: 0px 4px 5px -2px #adadad;
  -moz-box-shadow: 0px 1px 4px 0px #adadad;
  // -moz-box-shadow: 0px 4px 5px -2px #adadad;
  box-shadow: 0px 1px 4px 0px #adadad;
  // box-shadow: 0px 4px 5px -2px #adadad;
  p{
    line-height: 0.8;
  }
}

.contact-sticky.no-sticky{
  position: initial;
  width: 190px;
  // top: 0;
}

.banner-contact-wrapper{
  width: 685px;
  margin: auto;
  color: var(--title-color-blue);
  .banner-contact{
    background-color: #fff;
    box-shadow: 0 2px 4px 1px rgba(0,0,0, 0.4);
    p{
      font-size: 20px;
      margin-bottom: 0;
      span{
        font-weight: 700;
      }
    }
    .contact-sticky.no-sticky{
      margin-left: 30px;
    }
  }
}

.contact_full{
  position: fixed;
  border: 2px solid var(--title-color-blue);
  background-color: #fff;
  text-align: center;
  font-weight: 700;
  z-index: 100;
  .close-btn{
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: 0px;
    padding: 6px;
    background: #e4e4e4;
    width: 40px;
  }
  .left-contact{
    padding: 25px;
    background-color: var(--title-color-blue);
    p, a{
      color: var(--title-color-darkest-blue)!important;
    }
  }
  .right-contact{
    padding: 25px;
  }
}

.scroll-top-wrapper{
  background-color: #fff;
  position: fixed;
  bottom: 130px;
  right: 20px;
  cursor: pointer;
  border-radius: 100%;
  padding: 2px;
  z-index: 8;
  .scroll-top-btn{
    width: 40px; 
    height: 40px;
    fill: #999999
  }
  // &:hover{
  //   background-color: #000;
  //   .scroll-top-btn{
  //     fill: #fff
  //   }
  // }
}

/* .content-wrapper{
  margin-top: 15px;
} */

.btn{
  border-radius: 0!important;
}
.btn.custom{
  &:hover{
    background-color: var(--title-color-dark-blue);
    border-color: var(--title-color-dark-blue);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(36, 152, 201, 0.5);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 152, 201, 0.5);
  }
  &:active{
    background-color: var(--title-color-dark-blue)!important;
    border-color: var(--title-color-dark-blue)!important;
    box-shadow: 0 0 0 0.2rem rgba(36, 152, 201, 0.5)!important;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 152, 201, 0.5)!important;
  }
}

.swiper-container{
  padding-bottom: 35px!important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 0;
}
.swiper-button-next.outside-wrapper{
  right: 5px;
  z-index: 8;
}
.swiper-button-prev.outside-wrapper{
  left: 5px;
  z-index: 8;
}

@media only screen and (max-width: 767px){
  .banner-contact-wrapper{
    width: 500px;
  }
}
@media only screen and (max-width: 576px){
  .banner-contact-wrapper{
    width: 100%;
    .banner-contact{
      p{
        font-size: 16px;
      }
      .contact-sticky.no-sticky{
        margin-left: 0;
      }
    }
  }
  .contact-sticky:not(.no-sticky){
    width: 50px;
    bottom: 80px;
    padding: 3px;
    padding-top: 10px;
    -webkit-box-shadow: 0px 1px 4px 0px #adadad;
    // -webkit-box-shadow: 0px 4px 5px -2px #adadad;
    -moz-box-shadow: 0px 1px 4px 0px #adadad;
    // -moz-box-shadow: 0px 4px 5px -2px #adadad;
    box-shadow: 0px 1px 4px 0px #adadad;
    // box-shadow: 0px 4px 5px -2px #adadad;
    p{
      display: none;
    }
  }

  .contact_full{
    max-width: 320px;
    .left-contact{
      padding: 10px;
      background-color: var(--title-color-blue)
    }
    .right-contact{
      padding: 10px;
    }
  }
  
  .scroll-top-wrapper{
    bottom: 140px;
    right: 0;
  }
  .default-text-size{
    font-size: 16px;
  }
  .sm-text-size{
    font-size: 18px;
  }
  .md-text-size{
    font-size: 24px;
  }
  .lg-text-size{
    font-size: 28px;
  }
}

.lottie-wrapper{
  position: relative;
  .lottie-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.play-btn-wrapper{
  z-index: 100;
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;  
}

.play-btn{
  font-size: 50px;
  transition: 200ms ease;
  cursor: pointer;
  box-shadow: 0 0 6px 3px rgba(0,0,0, 0.4);
  border-radius: 100%;
  background: #fff;
  path:nth-of-type(1){
    fill: #fff;
  }
  path:nth-of-type(2){
    fill: #44B5E5;
  }
  &:hover{
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
  }
}

.right-contact u{
  color: rgb(41, 171, 226);
  cursor: pointer;
}

.loading-inline{
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 1000;
}

@media only screen and (max-width: 340px){
  .home-solution{
    margin-top: 20px;
  }
}