@font-face {
  font-family: "IBMPlexSans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: 
  url("./IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./IBMPlexSans-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "IBMPlexSans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./IBMPlexSans-Bold.ttf") format('truetype');
}