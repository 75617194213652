.ck-container>.ck-editor__editable:not(.ck-focused){
    border: none!important;
}
.ck.ck-reset_all, .ck.ck-reset_all *{
    display: none;
}

.ck-container figure{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    outline-color: #fff!important;
}

.ck-container figure img{
    max-width: 100%;
}

.ck-container .image>figcaption{
    background-color: transparent!important;
    border: none;
    text-align: center;
    font-size: 85%;
    padding: 3px 0;
}
.ck.ck-editor__editable_inline{
    padding: 0!important;
}

.ck-container ul,
.ck-container ol{
  padding-left: 20px;
}

.ck-container>.ck-editor__editable{
    border: none!important;
    box-shadow: none!important;
}

.ck-container h2{
    font-size: 32px;
    font-weight: 500;
}
.ck-container h3{
    font-size: 28px;
    font-weight: 500;
}
.ck-container h4{
    font-size: 24px;
    font-weight: 500;
}

@media only screen and (max-width: 480px){
    .ck-container figure{
        width: 100%;
    }
}